.header__subscription-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 62px;
    color: #363636;
    font-family: inherit;
    font-size: 20px;
    background: #fff;
    border: 1px solid #dbdbdb;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
}

@media screen and (min-width: 764px) {
    .header__subscription-button {
        order: 4;
        width: 22.1%;
        height: 100%;
        font-size: 16px;
        border-right: none;
        border-left: none;
    }
}

@media screen and (min-width: 1170px) {
    .header__subscription-button {
        order: 4;
        width: 208px;
        font-size: 20px;
    }
}
