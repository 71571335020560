.news-modal__title {
    color: #000;
}

.news-modal__news-props {
    margin-bottom: 20px;
}

.news-modal__date {
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
    font-size: 14px;
    color: #595959;
}

.news-modal__channel {
    display: inline-block;
    vertical-align: top;
    color: #428bca;
}

.news-modal__title {
    margin-bottom: 22px;
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    color: #222222;
}

.news-modal__image {
    display: block;
    max-width: 680px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 17px;
}


.news-modal__modal-body {
    overflow-y: auto;
}

.news-modal__modal-body::-webkit-scrollbar {
    display: none;
}

.news-modal__modal-body::-ms-scrollbar {
    display: none;
}

.news-modal__description {
    font-size: 16px;
    color: #595959;
    line-height: 24px;
    text-align: justify;
}

.news-modal__read-more {
    display: block;
    margin: 16px auto;
    padding: 9px 6.74%;
    font-family: inherit;
    font-size: 16px;
    color: #7698be;
    background: #ffffff;
    border-radius: 0;
    outline: none;
    border: 1px solid #7698be;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

.news-modal__read-more:hover {
    border: 1px solid #7698be;
    color: #fff;
    background-color: #7698be;
}

.modal-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 25px 0;
}

.modal-controls__item_no-left-border {
    border-left: none;
}

.modal-controls__item_social {
    width: 100%;
    margin-left: 17px;
    margin-top: 33px;
    border: none;
}

.modal-controls__item {
    display: inline-flex;
}

.modal-controls__button {
    display: inline-flex;
    margin-right: 2.10%;
    padding: 9px 4.74%;
    font-family: inherit;
    font-size: 3.7vw;
    color: #7698be;
    background: #ffffff;
    border-radius: 0;
    outline: none;
    border: 1px solid #7698be;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

}

.modal-controls__button:hover {
    color: #ffffff;
    background: #7698be;
}

@media screen and (min-width: 764px) {
    .modal-controls {
        justify-content: flex-start;
    }

    .modal-controls__button {
        margin-right: 20px;
        padding: 9px 25px;
        font-size: 16px;
    }

    .modal-controls__item_social {
        width: auto;
        margin-left: auto;
        margin-top: 0;
    }
}

@media screen and (min-width: 1170px) {
    .modal-controls {
        flex-wrap: nowrap;
    }

    .modal-controls__item_social {
        width: auto;
        margin-top: 0;
        margin-left: auto;
    }
}