.footer {
    width: 94.7%;
    min-height: 48px;
    margin: 48px auto 49px auto;
    margin-bottom: 49px;
    font-size: 16px;
    color: #363636;
}

.additional-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    list-style: none;
    text-align: center;
}

.additional-nav__link {
    color: #575757;
    margin-right: 20px;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: center center;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;
}


.additional-nav__link_facebook {
    color: transparent;
    background-image: url("./fb.svg");
    background-size: 12px 23px;
}

.additional-nav__link_vk {
    color: transparent;
    background-image: url("./vk.svg");
}

.additional-nav__link_logo {
    color: transparent;
    background-image: url("./logo.svg");
}

.additional__item {
    margin-bottom: 10px;
}

.additional__item_logo {
    width: 100%;
    min-height: 50px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    margin-bottom: 50px;
}

.additional__item_social {
    margin-top: 32px;
    margin-bottom: 20px;
    display: flex;
}

.additional-nav__link_social {
    width: 39px;
    height: 39px;
    margin-right: 10px;
    background-color: #cccccc;
    border-radius: 50%;
}

.feedback-modal-block__field-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.feedback__title {
    margin-top: 16px;
    margin-bottom: 16px;
}

.feedback__button {
    display: inline-block;
    vertical-align: top;
    margin: 10px auto;
    margin-right: 12px;
    padding: 4px 16px;
    border: 2px solid #000;
    color: #000;
    line-height: 45px;
    font-family: inherit;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
}

.feedback__button:last-child {
    margin-right: 0;
}

.feedback-modal-block__input {
    display: block;
    width: 100%;
    min-height: 65px;
    font-size: 20px;
    padding-left: 30px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.feedback-modal-block__input_message {
    padding-top: 12px;
}

@media screen and (min-width: 764px) {
    .footer {
        width: auto;
        display: flex;
        align-items: center;
        margin-top: 26px;
        margin-bottom: 31px;
        font-size: 12px;
        background: #fff;
        border: 1px solid #e7e7e7;
    }

    .footer__nav {
        width: 100%;
    }

    .additional-nav {
        flex-direction: row;
        align-items: center;
        list-style: none;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .additional-nav__link {
        white-space: nowrap;
    }

    .additional__item {
        margin-bottom: 0;
    }

    .additional__item_logo {
        display: block;
        width: auto;
        min-height: auto;
        margin-bottom: 0;
    }

    .additional__item_social {
        margin: 0 16px 0 auto;

    }
}

@media screen and (min-width: 1170px) {
    .footer {
        margin-top: 34px;
        font-size: 16px;
    }

    .additional__item_social {
        margin-right: 28px;
    }

    .additional-nav__link {
        margin-right: 40px;
    }
}