.news-container {
    height: 100%;
    padding: 7px;
    background: #fff;
    overflow: hidden;
}

.button-container {
    height: 53px;
    margin-top: 28px;
    background: #f6f5f5;
}

.button-more {
    display: block;
    width: 100%;
    height: 50px;
    font-family: inherit;
    font-size: 20px;
    color: #363636;
    font-weight: 700;
    background: #fff;
    border: 2px solid #767676;
    cursor: pointer;
}

.button-more:disabled {
    background: #aaa;
    cursor: auto;
}

.news-block_empty {
    text-align: center;
    margin-top: 40px;
    font-size: 20px;
    font-weight: 700;
}

@media screen and (min-width: 764px) {
    .news-container {
        width: calc(100vw - 2.23%);
        margin-left: -2.6%;
    }

    .news-block {
        width: calc(94.77% + 2.23%);
        overflow: hidden;
        margin: 0 auto;
    }

    .button-container {
        margin: 26px 0;
    }
}

@media screen and (min-width: 1170px) {
    .news-container {
        width: auto;
        margin-left: initial;
        padding-left: 8px;
        padding-right: 8px;
    }

    .news-block {
        width: auto;
    }

    .button-container {
        margin: 36px 0;
    }
}