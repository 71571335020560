.redirects-container {
    background-color: #ffffff;
}



.redirect-editor__section-title {
    grid-column: 1 / span 3;
}

.redirect-editor__add-link-button {
    display: inline-flex;
    grid-column: 2 / span 2
}

.redirect-editor__referer-input {
    grid-column: 2 / span 1;
}

.redirect-editor-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.redirect-editor__name-label {
    display: flex;
    align-items: center;
}

.redirect-editor__link-type-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}