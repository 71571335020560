.channel-block {
    display: flex;
    align-items: center;
    width: 82%;
    max-width: 900px;
}

.channel {
    display: flex;
    margin: 0 auto;
    margin-bottom: 14px;
    padding: 14px 12px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
}

.channel:last-child {
    padding-bottom: 40px;
}

.channel__image {
    display: block;
    width: 37.61%;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 37.61%;
    height: auto;
    max-height: 130px;
    margin-right: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.channel__details {
    flex-shrink: 1;
    margin-right: 6px;
}

.channel__title {
    display: block;
    margin-top: 18px;
    margin-bottom: 12px;
    font-size: 3.75vw;
    color: #3177a3;
}

.channel__description {
    font-size: 2.5vw;
    margin-bottom: 14px;
    color: #595959;
}

.channel__url {
    color: #a6a6a6;
    font-size: 2.5vw;
    line-height: 24px;
    text-transform: lowercase;
}

.channel__last-update-date {
    color: #a6a6a6;
    margin-top: 10px;
}

.channel__subscribe {
    display: block;
    width: 37px;
    height: 37px;
    line-height: 37px;
    flex-shrink: 0;
    align-self: center;
    margin-left: auto;
    background: #fff;
    font-size: 36px;
    color: #a6a6a6;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    cursor: pointer;
    box-sizing: border-box;
}

.channel__subscribe:hover:not(:disabled) {
    background: #D6E48F;
}

.channel__subscribe_subscribed {
    background: #ffcd03;
    border: none;
    color: #fff;
}

.channel__subscribe:active:not(:disabled) {
    background: #fff;
}

.channel__subscribe:disabled {
    background-color: #eee;
}

.channel_hidden .channel__title {
    color: #cfd1cf;
}

.channel_hidden .channel__image {
    filter: grayscale(100%);
}

.channel_hidden+.channel-block__subscribe_subscribed {
    background: #cfd1cf;
}

.channel__control-buttons {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    justify-content: center;
    margin-left: auto;
}

@media screen and (min-width: 764px) {
    .channel {
        display: flex;
        width: 90%;
        max-width: 900px;
        min-height: 130px;
        margin: 0 auto;
        margin-bottom: 14px;
        border: 1px solid #e9e9e9;
    }

    .channel__image {
        width: 23.20%;
        max-width: 23.20%;
    }

    .channel__details {
        margin-right: 8px;
    }

    .channel__title {
        margin-bottom: 20px;
        font-size: 2.36vw;
    }

    .channel__description {
        margin-top: 25px;
        margin-bottom: 17px;
        font-size: 1.57vw;
    }

    .channel__url {
        font-size: 1.57vw;
    }
}

@media screen and (min-width: 1170px) {
    .channel {
        padding: 12px 50px 12px 12px;
    }

    .channel__image {
        width: 323px;
        max-width: 323px;
    }

    .channel__details {
        margin-left: 30px;
        margin-right: 16px;
    }

    .channel__title {
        margin-top: 27px;
        font-size: 30px;
    }

    .channel__description {
        font-size: 16px;
        line-height: 24px;
    }

    .channel__url {
        font-size: 16px;
    }

    .channel__subscribe {
        width: 42px;
        height: 42px;
    }
}