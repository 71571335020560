.channel-filter-wrapper {
    margin-bottom: 23px;
}

.channel-filter {
    display: flex;
    list-style: none;
    color: #a6a6a6;

}

.channel-filter__item {
    display: flex;
    height: 32px;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 2.08vw;
    border: 1px solid #dadada;
    background: #fff;
    border-top: none;
    border-left: none;
    text-transform: capitalize;
    box-sizing: border-box;
}

.channel-filter__item_caption {
    padding-right: 10px;
    flex-grow: 0;
    font-size: 2.91vw;
    line-height: 18px;
    font-family: inherit;
    color: #363636;
    border: none;
    background: transparent;
    border-right: 1px solid #dadada;
    white-space: nowrap;
}

.channel-filter__item:nth-child(5n) {
    border-left: none;
}

.channel-filter_top .channel-filter__item:not(:first-child) {
    border-top: 1px solid #dadada;
}

.channel-filter__buton {
    display: block;
    width: 100%;
    height: 100%;
    font-size: inherit;
    font-family: inherit;
    background: transparent;
    color: #afafad;
    cursor: pointer;
    white-space: nowrap;
    /* padding: 0 12px; */
}

.channel-filter__buton_active {
    background: #ffcd03;
    color: #fff;
}

@media screen and (min-width: 764px) {
    .channel-filter__item {
        height: 38px;
        font-size: 1.83vw;
    }

    .channel-filter__item_caption {
        padding-right: 2.88%;
        font-size: 1.83vw;
        line-height: 18px;
    }

    .channel-filter__buton {
        /* padding: 0 6.54%; */
    }

}


@media screen and (min-width: 1170px) {
    .channel-filter-wrapper {
        margin-bottom: 40px;
    }

    .channel-filter__item {
        height: 56px;
        font-size: 20px;
    }

    .channel-filter__item_caption {
        padding-right: 50px;
        font-size: 20px;
    }

    .channel-filter__buton {
        padding: 0 9.5px;
    }

}