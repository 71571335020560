.news-modal__title {
    color: #000;
}

.news-modal__news-props {
    margin-bottom: 20px;
}

.news-modal__date {
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
}

.news-modal__channel {
    display: inline-block;
    vertical-align: top;
    color: #428bca;
}

.news-modal__title {
    margin-bottom: 10px;
}

.news-modal__image {
    display: block;
    max-width: 470px;
    margin: 0 auto;
    margin-bottom: 17px;
}

.news-modal__modal-body {
    overflow-x: hidden;
    overflow-y: auto;
}

.news-modal__modal-body::-webkit-scrollbar {
    display: none;
}

.news-modal__modal-body::-ms-scrollbar {
    display: none;
}