.privacy-policy-container {
    width: 60%;
    margin: 0 auto;
    color: #1d1d1b;
    margin-bottom: 30px;
}

.policy-header {
    margin-bottom: 20px;
}

.policy-usage {
    margin-bottom: 15px;
}

.numbered-list {
    list-style: none;
    counter-reset: li;
}

.numbered-list__top > li {
    margin-bottom: 20px;
}

.numbered-list__top:last-child {
    margin-bottom: 40px;
}

.numbered-list__nested {
    margin-bottom: 5px;
}


.numbered-list__top li:before {
    display: inline-flex;
    vertical-align: baseline;
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
}

.numbered-list__nested li:before {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
}


.numbered-list li h2 {
    display: inline-flex;
    margin-bottom: 10px;
}

.numbered-list li:before {
    counter-increment: li;
    content: counters(li, '.') '. ';
}

.policy-section {
    margin-bottom: 10px;
}