.news-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 402px;
    margin-bottom: 8px;
    border: 2px solid #cdcdcd;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
}

.news-item__link {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 26px 26px 16px 26px;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
}

.news-item__link:hover {
    padding: 26px 26px 16px 26px;
}

.news-item__pictured .news-item__link {
    color: #fff;
    background-image: linear-gradient(-180deg, transparent 4%, rgba(0, 0, 0, .03) 10%, rgba(0, 0, 0, .06) 16%,
            rgba(0, 0, 0, .09) 19%, rgba(0, 0, 0, .11) 22%, rgba(0, 0, 0, .16) 26%, rgba(0, 0, 0, .19) 32%, rgba(0, 0, 0, .23) 38%, rgba(0, 0, 0, .26) 47%, rgba(0, 0, 0, .30) 57%, rgba(0, 0, 0, .37) 65%);
}

.news-item__blank .news-item__link {
    justify-content: flex-start;
    margin-top: auto;
    padding-top: 8px;
    color: inherit;
}

news-item__pictured .news-item__info {
    margin-bottom: 14px;
}

.news-item__channel {
    display: inline-block;
    vertical-align: top;
    background-color: #ffcd03;
    font-weight: 700;
    margin-right: 13px;
    padding: 3px 16px;
    line-height: 24px;
}

.news-item__info {
    margin-bottom: 13px;
}

.news-item__date {
    display: inline-block;
    vertical-align: top;
    padding: 0 16px;
}

.news-item__title {
    font-size: 20px;
    font-weight: 700;
}

.news-item__blank .news-item__title {
    margin-bottom: 21px;
    color: inherit;
    font-size: 30px;
    line-height: 33px;
}

.news-item__blank .news-item__description {
    line-height: 24px;
}

.news-item__pictured .news-item__description {
    display: none;
}

.news-item__description {
    word-wrap: break-word;
}

@media screen and (min-width: 764px) {
    .news-item {
        padding: 0;
        box-sizing: content-box;
    }

    .news-item__blank .news-item__link {
        overflow: hidden;
    }

    .news-item__block {
        position: relative;
    }

    .big-tiles .news-item {
        width: 48%;
        min-height: 330px;
        margin-bottom: 23px;
    }

    .big-tiles .news-item_wide {
        width: calc(96% + 20px + 4px);
    }

    .news-item__pictured .news-item__info {
        margin-bottom: 20px;
    }

    .big-tiles .news-item__info {
        padding: 0 18px;
    }

    .big-tiles .news-item__block {
        padding: 0 18px 28px 18px;
    }

    .big-tiles .news-item__link:hover .news-item-title,
    .big-tiles .news-item__link:hover .news-item-description {
        color: #fff;
    }

    .big-tiles .news-item__title {
        font-size: 20px;
        margin-bottom: 16px;
        -webkit-line-clamp: 9;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .small-tiles .news-item-block {
        padding: 0 12px 22px 12px;
    }

    .small-tiles .news-item__title {
        font-size: 16px;
        margin-bottom: 12px;
    }

    .news-item__pictured .news-item__link:hover {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }

    .news-item__pictured .news-item__link:hover::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.12) 11%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 100%);
    }

    .news-item:hover .news-item__description {
        display: block;
    }

    .news-item__link:hover .news-item-block {
        color: #fff;
    }
}

@media screen and (min-width: 1170px) {
    .big-tiles .news-item {
        width: 366px;
        height: 333px;
    }

    .small-tiles .news-item {
        width: 264px;
        height: 270px;
    }

    .big-tiles .news-item_wide {
        width: 755px;
    }

    .small-tiles .news-item_wide {
        width: 555px;
    }

    .news-item__info {
        padding: 0 18px;
    }

    .news-item__block {
        position: relative;
        padding: 28px 18px;
    }

    .news-item__title {
        font-size: 20px;
        margin-bottom: 16px;
    }
}