* {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
}

html,
body {
    height: 100%;
}

body {
    min-width: 320px;
    background: #f6f5f5;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
}

#root {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100%;
}

.item-page-viewer {
    visibility: hidden;
}

.button {
    padding: 9px 25px;
    font-size: 14px;
    color: #4188ae;
    background: #ffffff;
    border-radius: 0;
    outline: none;
    border: 1px solid #ccc;
    cursor: pointer;
}

.section-editor {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 16px;
}


.section-editor>* {
    margin-bottom: 16px;
}

.section-editor__section-title {
    grid-column: 1 / span 3;
}

.section-editor__name-label {
    display: flex;
    align-items: center;
}

.text-disabled {
    color: #aaaaaa;
}

@import url("./styles/controls.css");
@import url("./styles/table.css");

.container {
    width: 94.7%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.page-title {
    margin-bottom: 26px;
    color: #000000;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}

.page-subtitle {
    text-align: center;
    font-size: 24px;
    margin-bottom: 16px;
}

.page-section {
    background-color: #ffffff;
    height: 100%;
    padding: 7px;
}

@media screen and (min-width: 764px) {
    .container {
        width: 100%;
        margin: initial;
    }

    .content-block {
        width: 94.77%;
        margin-left: auto;
        margin-right: auto;
    }

    .page-section {
        background-color: #ffffff;
        height: 100%;
        padding: 40px 35px;
    }
}

@media screen and (min-width: 1170px) {
    main {
        margin: 0 auto;
    }

    .content-block {
        width: 1170px;
    }

    .container {
        width: 100%;
        margin: initial;
        margin-top: 6px;
    }
}

iframe {
    z-index: 0 !important;
    position: static !important;
    width: 0 !important;
}

#webpack-dev-server-client-overlay {
    position: fixed !important;
}

/* hide liveinternet icon */
img[title="LiveInternet"] {
    display: none;
}