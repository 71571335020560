.search-pannel {
    display: flex;
    width: 100%;
    height: 62px;
    flex-grow: 1;
    border: 1px solid #dbdbdb;
    border-top: none;
    border-bottom: none;
    box-sizing: border-box;
}

.search-pannel__input {
    flex-grow: 1;
    width: 91.46%;
    height: 100%;
    padding-left: 25px;
    font-family: inherit;
    font-size: 20px;
    color: #000;
    border: none;
    outline: 0;
    background: #fff;
    -webkit-appearance: none;
}

.search-pannel__input::placeholder {
    color: #a6a6a6;
}

.search-pannel__button {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-right: 30px;
    height: 100%;
    background: #fff;
    font-size: 18px;
    border: none;
    outline: 0;
    color: transparent;
    background-image: url("./magnifier.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px 24px;
    cursor: pointer;
}

.search-pannel__input_disabled {
    cursor: auto;
    /* mask-size: 20px;
    mask-repeat: no-repeat;
    mask-position: center; */
    background-color: #fff;
}

@media screen and (min-width: 764px) {
    .search-pannel {
        order: 2;
        width: 8.29%;
        height: auto;
        border: 1px solid #dbdbdb;
    }

    .search-pannel__input {
        display: none;
    }

    .search-pannel__button {
        width: 100%;
        justify-content: center;
        padding-right: 0;
    }
}

@media screen and (min-width: 1170px) {
    .search-pannel {
        order: 2;
        display: inline-flex;
        width: 270px;
        border: 1px solid #dbdbdb;
    }

    .search-pannel__input {
        display: block;
    }

    .search-pannel__button {
        width: auto;
        justify-content: flex-start;
        padding-right: 14px;
    }
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
}

.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-left: 0 !important;
}