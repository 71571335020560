.header__logo-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    min-height: 60px;
    border: 1px solid #dbdbdb;
    border-right: none;
    text-decoration: underline;
    color: transparent;
    background: #fff;
    background-image: url("./logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    box-sizing: border-box;

}

@media screen and (min-width: 764px) {
    .header__logo-button {
        width: 8.56%;
        height: auto;
    }
}

@media screen and (min-width: 1170px) {
    .header__logo-button {
        width: 92px;
    }
}
