.confirmation__block {
    width: 100%;
    margin: 40px auto;
}

@media screen and (min-width: 800px) {
    .confirmation__block {
        width: 600px;
        margin: 40px auto;
    }
}