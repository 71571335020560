.confirmation__block {
    text-align: center;
}

.confirmation__message {
    font-size: 20px;
    text-align: center;
}

.confirmation__button {
    display: block;
    margin: 10px auto;
    padding: 4px 10px;
    border: 2px solid #000;
    color: #000;
    line-height: 45px;
    font-family: inherit;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
}

.confirmation__link {
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;
}