.social-link-share-container {
    list-style: none;
}

.social-link-container {
    display: flex;
    flex-direction: row-reverse;
    list-style: none;
}

.social-link-fb {
    background-size: 9px 15px;
}

.social-link-fb svg {
    width: 15px;
    height: 15px;
}

.social-link-vk svg {
    width: 15px;
    height: 15px;
}

.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29px;
    height: 29px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #8d9da2;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.social-link:hover {
    background-color: #2d4485;
}

.socail-link__screen-readers {
    width: 0;
    color: transparent;
}


@media screen and (min-width: 764px) {
    .social-link-container {
        flex-direction: flex-start;
    }
}