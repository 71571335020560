.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
}

.pagination__item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    margin-left: 6px;
    border: 1px solid #505050;
}

.pagination__item.disabled a {
    color: #aaa;
    cursor: auto;
}

.pagination__item a {
    text-decoration: none;
    cursor: pointer;
}


.pagination__item.active {
    background-color: lightskyblue;
}

.pagination__item.active a {

    color: #fff;
}

.pagination__item a:hover,
.pagination__item a:focus {
    text-decoration: none;
    outline: none;
}

.channel-modal__button-more {
    display: block;
    margin: 16px auto;
    color: #a6a6a6;
    font-family: inherit;
    border: 2px solid #a6a6a6;
    background: transparent;
    cursor: pointer;
}

.channel-modal__button-more:hover,
.channel-modal__button-more:active,
.channel-modal__button-more:focus {
    color: #363636;
    border: 2px solid #363636;
}

.channel-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    margin-left: -2.62%;
    min-height: 96px;
    background: #fff;
}

.channel-buttons__button {
    width: 40%;
    color: #a6a6a6;
    font-family: inherit;
    font-size: 12px;
    margin-right: 10px;
    padding: 6px;
    border: none;
    background: transparent;
    border: 2px solid #a6a6a6;
    cursor: pointer;
}

.channel-buttons__button:hover,
.channel-buttons__button:active,
.channel-buttons__button:focus {
    color: #363636;
    border: 2px solid #363636;
}

@media screen and (min-width: 960px) {

    .channel-modal__button-more {
        width: 472px;
        margin-bottom: 110px;
        min-height: 52px;
        font-size: 20px;
    }

    .channel-buttons__button {
        width: 472px;
        min-height: 52px;
        font-size: 20px;
    }

    .channel-buttons__button:first-child {
        margin-right: 20px;
    }
}

.channel-no-more {
    width: 100%;
    height: 67px;
}

.channel-list-validation {
    font-size: 20px;
}