.profile {
    width: 89.33%;
    max-width: 750px;
    margin: 0 auto;
}

.profile__title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 26px;
}

.profile__title::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    background: url("./profile.svg");
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    background-size: 100px;
}

.profile__details {
    background: #fff;
    border: 1px solid #e9e9e9;
}

.profile__block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 41px 4.77%;
    border: 1px solid #eeeeee;
    border-bottom: none;
    min-height: 40px;
    line-height: 40px;
}

.profile__block_edit-mode {
    position: relative;
    background-color: #f2f2f2;
}

.profile__block__view-mode-button {
    z-index: 0;
}

.profile__field {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    font-size: 25px;
    font-weight: 700;
    color: #8a8891;
    font-weight: 700;
}

.profile__value {
    display: inline-block;
    vertical-align: top;
    margin-right: 6px;
    font-size: 20px;
}

.profile__action {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: auto;
    background-color: transparent;

    background-repeat: no-repeat;
    color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.profile__action_edit {
    background-image: url("./profile-edit.svg");
}

.profile__action_add {
    background-image: url("./profile-add.svg");
}

.profile__block__input {
    display: block;
    width: 91.04%;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: inherit;
    padding: 24px;
}

.profile__block__input::placeholder {
    color: #1d1d1b;
}

.profile__block_save-button {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 3%;
    padding: 24px 6.71%;
    border: none;
    outline: none;
    color: #fff;
    font-size: 20px;
    background-color: #000;
    cursor: pointer;
}

.profile__block_save-button:last-child {
    margin-right: 0;
}

.profile__block__error {
    position: relative;
    display: block;
    width: 98.04%;
    margin-bottom: 10px;
    padding: 20px 0;
    color: #000;
    font-size: 20px;
    text-align: center;
    background-color: #e8abad;
}

.profile__block__error::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #e8abad transparent transparent transparent;
    bottom: -19px;
    left: calc(50% - 10px);
}

.profile__change-password-form {
    width: 100%;
}

@media screen and (min-width: 764px) {
    .profile {
        width: 89.33%;
    }
}