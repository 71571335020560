.page-header {
    display: flex;
    flex-wrap: wrap;
    width: 92.7%;
    margin: 18px auto;
    justify-content: center;
    background-color: #fff;
}

.page-header::after {
    content: "";
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 8px;
    background: linear-gradient(180deg, #f7fafd 72.36%, hsla(0, 0%, 100%, 0));
}

@media screen and (min-width: 764px) {
    .page-header {
        display: flex;
        max-width: 1170px;
        flex-wrap: nowrap;
        margin: 0 auto;
        justify-content: center;
        height: 60px;
        margin: 33px auto;
        margin-bottom: 138px;
    }

    .anonym-shim {
        width: 350px;
        order: 3;
        background-color: #fff;
        border-top: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
    }
}

@media screen and (min-width: 1170px) {
    .page-header {
        position: sticky;
        top: 0;
        z-index: 100;
        margin: 20px auto;
        margin-bottom: 6px;
        z-index: 1;
    }
}