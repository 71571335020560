.redirects-container__add-new-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.redirects-container__add-new-redirect {
    width: 42px;
    height: 42px;
    line-height: 42px;
    flex-shrink: 0;
    align-self: center;
    background: #fff;
    font-size: 36px;
    color: #a6a6a6;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
}

.redirects-container__add-new-redirect:hover {
    background: #ffcd03;
    border: none;
}