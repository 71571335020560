.table {
    width: 100%;
    background-color: #E5E5E5;
    border-collapse: collapse;
}

.table th {
    text-align: left;
    background-color: #D6E48F;
}

.table th,
.table td {
    padding: 6px;
}

.table td {
    color: #595959;
}