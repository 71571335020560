.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
}

.ReactModal__Content.ReactModal__Content--after-open {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 12;

    overflow: auto;
    overflow-y: scroll;
}

.ReactModal__Body--open {
    position: static;
    overflow-y: scroll;
    overflow: hidden;
}

.ReactModal__Html--open {
    overflow-y: hidden;
}

.ReactModalPortal {
    position: fixed;
    z-index: 1 !important;
    height: 1px;
    width: 1px;
}

.modal-dialog {
    position: relative;
    width: 100%;
    max-width: 770px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    padding: 26px 8.48%;
    background: #fff;
    color: #1d1d1b;
    box-sizing: border-box;
}

.close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    background: transparent;
    cursor: pointer;
    z-index: 100;
    color: transparent;
}

.close:hover {
    opacity: 1;
}

.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #919191;
    z-index: 100;
}

.close:before {
    transform: rotate(45deg) translate(-13px, -11px);
}

.close:after {
    transform: rotate(-45deg) translate(11px, -13px);
}

.modal__move-button {
    display: none;
    position: absolute;
    top: 100px;
    width: 40px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    outline: none;
    background-image: url('./arrow-white.svg');
    cursor: pointer;
}

.modal__move-button[disabled] {
    opacity: 0.7;
    cursor: auto;
}

@media screen and (min-width: 764px) {
    .modal-dialog {
        width: 68.5%;
        max-width: 646px;
        padding: 20px 60px;
        box-sizing: content-box;
    }

    .modal-dialog_news {
        width: calc(100vw - 150px);
        max-width: none;
        height: 100vh;
        margin-top: 0;
    }

    .modal__move-button {
        display: block;
        top: 68px;
    }

    .account-modal .modal-dialog {
        max-width: 500px;
    }

    .modal__move-button_next {
        right: 12px;
        transform: rotate(360deg);
    }

    .modal__move-button_prev {
        left: 12px;
        transform: rotate(177deg);

    }
}

.account-modal-block__title {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 600;
}

.account-modal-block__field-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.account-modal-block__input {
    display: block;
    width: 100%;
}

.account-modal-block__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 70px;
    margin-bottom: 16px;
    color: #fff;
    background: #02101b;
    cursor: pointer;
}

.account-modal-block__button:disabled {
    background: #ccc;
    color: #000;
}

.account-modal__link-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-modal-block__link {
    margin-right: 10px;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    line-height: 20px;
    background-color: transparent;
    cursor: pointer;
    color: #2a6496;
    border: none;
}

.account-modal-block__link:active,
.account-modal-block__link:focus {
    outline: none;
    border: none;
}

.modal-block__error {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 4px;
    color: #000;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    background-color: #e8abad;
}

.modal-block__error:before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    bottom: -19px;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #e8abad transparent transparent transparent;
}

.modal-block__error_summary {
    top: -16px;
    bottom: auto;
}

.account-modal-block__message {
    margin: 20px;
    margin-bottom: 40px;
    font-size: 20px;
}