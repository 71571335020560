.registration-block {
    padding-bottom: 50px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 40px;
}


.registration-block-link {
    display: block;
    text-align: center;

}