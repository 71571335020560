.confirmation__message {
    text-align: center;
    font-family: inherit;
    font-size: 22px;
}

.confirmation__error {
    text-align: center;
    font-family: inherit;
    font-size: 22px;
    color: red;
}

.confirmation__email-sent {
    text-align: center;
}

.confirmation__title {
    font-size: 40px;
}

.confirmation__message {
    font-size: 30px;
}
