.carousel-container {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    order: 3;
}

.carousel {
    position: relative;
    justify-content: center;
    width: 100%;
    display: flex;
    height: 74px;
    align-items: flex-end;
    background-color: #f6f5f5;
}

.carousel__slider {
    width: 100%;
    margin-right: 4px;
    padding-top: 4px;
}

.carousel__slider-tray--horizontal {
    display: flex;
    align-items: flex-end;
}

.carousel__control {
    position: absolute;
    display: block;
    width: 8.12vw;
    max-width: 38px;
    height: calc(100% - 8px);
    margin-top: 4px;
    margin-right: 2px;
    background: transparent;
    transition: all 1s ease;
}

.carousel__control:hover svg {
    transition: all 1s ease;
    scale: 1.2;
}

.carousel__back-button {
    display: none;
}

.carousel__next-button {
    display: none;
}

.carousel__control svg {
    stroke: #000000;
}

.carousel__control:disabled svg {
    stroke: #aaaaaa;
}

.slider__item {
    height: 58px;
    max-height: 58px;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 0 !important;
    transition: max-height 300ms ease;
}

.slider__item_active {
    height: 70px;
    max-height: 70px;
    transition: max-height 300ms ease;
}

.slider__item:nth-child(1n) {
    background-color: #D9562C;
}

.slider__item:nth-child(2n) {
    background-color: #7DBD68;
}

.slider__item:nth-child(3n) {
    background-color: #CDE356;
}

.slider__item:nth-child(4n) {
    background-color: #E0984E;
}

.slider__item:nth-child(5n) {
    background-color: #4E73A0;
}

.slider__item:hover {
    background: #fff;
}

.slider__item.slider__item_active {
    background: #000000;
    color: #ffffff;
}

.slider__channel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    color: #000000;
    font-size: 2.91vw;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}

.slider__channel:hover:not(.slider__channel_active) {
    color: #000;
}

.slider__channel_active {
    height: 72px;
    color: #CBE3C3;
}

.carousel__slide--hidden .slider__channel {
    background: transparent;
    box-shadow: none;
    transition: background-color 1000ms linear;
}

@media screen and (min-width: 764px) {
    .carousel-container {
        position: static;
        justify-content: flex-end;
        margin-left: auto;
        margin-bottom: 0;
        order: 0;
    }

    .carousel__slider {
        width: 764px;
    }

    .carousel__control {
        width: 38px;
    }

    .slider__channel {
        font-size: 14px;
    }

    .slider__channel:hover {
        box-shadow: none;
    }

    .slider__channel_active {
        box-shadow: none;
    }

    .carousel__back-button {
        display: block;
        left: -48px;
    }

    .carousel__next-button {
        display: block;
        right: -48px;
    }
}

@media screen and (min-width: 1170px) {
    .carousel-container {
        width: auto;
        justify-content: flex-start;
        margin-left: initial;
        margin-bottom: 6px;
    }

    .carousel__slider {
        width: 1170px;
        margin-right: 4px;
        padding-top: 4px;
    }

    .slider__channel {
        font-size: 18px;
        padding: 0 24px;
    }
}