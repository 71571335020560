.login-pannel {
    width: 52.6%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    font-size: 20px;
    border: 1px solid #dbdbdb;
    color: #363636;
    box-sizing: border-box;
}

.login-pannel__login {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #363636;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;

}

.login-pannel__user {
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;
    cursor: pointer;
    color: #363636;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    z-index: 1;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.login-pannel__user:hover .login-pnnel__user-menu {
    display: block;
}

.login-pannel__user-menu {
    visibility: hidden;
    position: absolute;
    top: 58px;
    width: 100%;
    list-style: none;
    opacity: 0;
    transition: 0.7s opacity;
    border-top: 1px solid #dbdbdb;
}

.login-pannel__user-menu_opened {
    display: block;
    visibility: visible;
    opacity: 1;
}

.login-pannel__user-menu-item {
    background: #fff;
    border: 1px solid #dbdbdb;
    border-top: none;
    height: 60px;
}

.login-pannel__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: inherit;
    color: #363636;
    text-align: center;
    background: #fff;
    cursor: pointer;
}

@media screen and (min-width: 764px) {
    .login-pannel {
        order: 6;
        width: auto;
        flex-grow: 1;
    }

    .login-pannel {
        font-size: 16px;
    }

    .login-pannel__user-menu_opened {
        opacity: 1;
        transition: 1s opacity;
    }

    .login-pannel__user-menu-item {
        height: 60px;
    }

    .login-pannel__user-menu {
        width: calc(100% + 2px);
        z-index: 1;
    }
}


@media screen and (min-width: 1170px) {
    .login-pannel {
        flex: 1;
        width: 316px;
        background-color: #fff;
        border: 1px solid #dbdbdb;
    }


}