.channel-controls {
    margin-top: 58px;
}

.channel-search {
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 12px;
    line-height: 14px;
    border: 1px solid #dadada;
    box-sizing: border-box;
}

.channel-search__url {
    width: 82%;
    height: 60px;
    padding: 0px 6px;
    color: #a6a6a6;
    font-family: inherit;
    font-size: 12px;
    box-sizing: border-box;
}

.channel-search__url::placeholder {
    color: #afafad;
    font-size: 10px;
    white-space: pre-line;
    padding-top: 0;
}

.channel-search__button {
    width: 30.75%;
    color: #afafad;
    background: #fff;
    text-align: center;
    background-image: url("./magnifier.svg");
    background-repeat: no-repeat;
    background-position: right 4px center;
    background-size: 20px 20px;
    cursor: pointer;
}

@media screen and (min-width: 764px) {
    .channel-controls {
        margin-top: 33px;
    }

    .channel-search__url {
        width: 90%;
        padding: 23px 24px 23px 24px;
    }

    .channel-search__button {
        width: 146px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: 15px;

    }

    .channel-search__url {
        padding: 15px 20px 15px 20px;
        font-size: 16px;
    }

    .channel-search__url::placeholder {
        font-size: 16px;
    }
}

@media screen and (min-width: 1170px) {
    .channel-search {
        margin-block: 16px;
        padding-right: 16px;
    }
}