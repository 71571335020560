.error-modal__title {
    display: block;
    margin-bottom: 22px;
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    color: #222222;
    margin-bottom: 24px;
}

.error-modal__body {
    font-size: 16px;
    color: #595959;
    line-height: 24px;
    margin-bottom: 24px;
}