.button:hover {
    color: #ffffff;
    background: #4188ae;
    text-decoration: none;
}

.button-link {
    color: #2a6496;
    background-color: transparent;
    cursor: pointer;
}

.button-link :hover:not(:disabled) {
    color: #4188ae;
}

.button-link:active,
.button-link:focus {
    outline: none;
}

.button-link:disabled {
    color: #aaa;
    cursor: pointer;
}

a {
    color: #2a6496;
    text-decoration: none;
}

.form-error {
    margin-top: 4px;
    color: #b94a48;
}

.checkbox-wrapper {
    display: inline-flex;
    align-items: center;
}

.checkbox-wrapper label {
    margin-left: 8px;
}

.text-input {
    min-height: 45px;
    font-size: 18px;
    padding-left: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.text-input_large {
    min-height: 65px;
    font-size: 20px;
    padding-left: 30px;
}

.text-input_error {
    border: 1px solid #b94a48;
}

.wide-save-cancel-controls {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.wide-save-cancel-controls__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    color: #a6a6a6;
    font-family: inherit;
    padding: 6px;
    background: transparent;
    border: 2px solid #a6a6a6;
    cursor: pointer;
    min-height: 52px;
    font-size: 20px;
    box-sizing: border-box;
    appearance: button;
}

.wide-save-cancel-controls__button:hover:not(:disabled) {
    color: #363636;
    border: 2px solid #363636;
}

button:disabled {
    cursor: auto;
}