.ReactModal__Content.ReactModal__Content--after-open.channel-modal {
    top: 0px;
    width: 100%;
    max-width: 100%;
    background-color: #f6f5f5;
}

.modal-dialog_full-screen {
    margin-top: 0;
}

.channel-modal__dialog {
    padding-left: 4.16%;
    padding-right: 4.16%;
    box-sizing: border-box;
}

@media screen and (min-width: 764px) {
    .channel-modal__dialog {
        width: 100%;
        max-width: 100vw;
        padding-left: 2.62%;
        padding-right: 2.62%;
    }
}