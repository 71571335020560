
.registration-banner {
    position: relative;
    background: #f3a075;
    color: #3b3b3b;
    text-align: center;
    padding: 38px 0 30px 0;
    margin-bottom: 2px;
}

.registration-banner__title {
    font-size: 20px;
    font-weight: 400;
}

.registration-banner__logo {
    font-weight: 700;
}

.registration-banner__title:after {
    content: "";
    display: block;
    width: 47.5%;
    margin: 20px auto 0 auto;
    margin-top: 25px;
    border-bottom: 2px solid #fff;
}

.registration-banner__description {
    width: 38.88%;
    margin: 18px auto 25px auto;
    line-height: 24px;
}

.registration-banner__register-link {
    display: inline-block;
    width: 240px;
    border: 2px solid #fff;
    color: #fff;
    line-height: 45px;
    font-family: inherit;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
}

.registration-banner__close {

}

.registration-banner__close::before,
.registration-banner__close::after {

    background-color: #fff;
}

@media screen and (min-width: 764px) {

    .registration-banner {
        /* transform: translateY(-30px); */
        margin-bottom: 0;
    }
}