.login {
    padding: 20px 0;
}

.login__title {
    display: block;
    margin-bottom: 25px;
    padding-top: 10px;
    color: #1d1d1b;
    font-size: 30px;
    border-top: 1px solid #e5e5e5;
}

.login-type {
    margin-bottom: 18px;
}

.login-type__title {
    display: block;
    margin-bottom: 18px;
}

.social-logins {
    display: block;
    list-style: none;
}

.social-logins__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    margin-bottom: 12px;
    text-align: center;
    font-size: 25px;
    line-height: 36px;
    color: #fff;
    text-transform: uppercase;
    box-sizing: border-box;
    cursor: pointer;
}

.social-logins__link_fb {
    background-color: #305d9d;
}

.social-logins__link_vk {
    background-color: #5181b8;
}

.social-logins__link_google {
    background-color: #4285f4;
}

.social-logins__link_twitter {
    background-color: #41abe1;
}

.login-block {
    display: grid;
    grid-template-columns: 40% 58%;
    row-gap: 20px;
    align-items: center;
}

.login-block__label {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: right;
    padding-right: 8px;
}

.login-block__input {
    width: 100%;
    padding: 4px 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    border: 1px solid #1d1d1b;
}

.login-block__remember-input {
    margin-right: 4px;
}


.register-agrements {
    margin: 0 auto;
    color: #1d1d1b;
    margin-left: 30px;
}

.login__register-agrements {
    margin-top: 20px;
}

.login__agrement {
    text-align: right;
}

.login-block__remember {
    grid-column: 2;
    display: flex;
    align-items: center;

    margin: 16px 0;
}

.login-block__login-button {
    margin-left: auto;
}

.login__register-block {
    grid-column: 2;
    display: flex;
    justify-content: space-between;
}